<template>
  <FormKit
    type="form"
    :actions="false"
    id="subscriberForm"
    @submit="sendFormData"
  >
    <div class="input-group">
      <div class="name-inputs">
        <FormKit
          type="text"
          :label="formItems.firstNameForm"
          :placeholder="formItems.firstNameForm"
          name="firstName"
          validation="required"
          :validation-messages="{required: formItems.firstNameFormError}"
          input-class="first-name-input"
          data-cy-form="first-name"
        />
        <FormKit
          type="text"
          :label="formItems.lastNameForm"
          :placeholder="formItems.lastNameForm"
          name="lastName"
          validation="required"
          :validation-messages="{required: formItems.lastNameFormError}"
          input-class="last-name-input"
          data-cy-form="last-name"
        />
      </div>
      <div>
        <FormKit
          type="email"
          :label="formItems.emailForm"
          :placeholder="formItems.emailForm"
          name="email"
          validation="required|email"
          :validation-messages="{required: formItems.emailFormError, email: formItems.emailFormError}"
          input-class="email-input"
          data-cy-form="email"
        />
      </div>
    </div>
      <div class="checkbox-section">
        <FormKit 
          type="checkbox"
          name="contactConsent"
          :label="formItems.optInOneForm"
          :value="false"
          validation="accepted"
          :validation-messages="{accepted: formItems.optInOneFormError}"
          decorator-class="slider round"
          data-cy-form="optin-one"
        />
        <FormKit
          v-if="showOptInTwo"
          type="checkbox"
          name="termsAndConditions"
          :label="formItems.optInTwoForm"
          :value="false"
          decorator-class="slider round"
          data-cy-form="optin-two"
        />
      </div>
  </FormKit>
  <subscription-form-button 
    :buttonText="getButtonText"
    @click="formSubmit"
  />
</template>
<script>
import SubscriptionFormButton from "./SubscriptionFormButton";

export default {
  name: 'SubscriptionForm',
  components: {
    SubscriptionFormButton
  },
  props: {
    formItems: {
      type: Object,
      required: true,
    },
    storeName: {
      type: String,
      required: true
    }
  },
  computed: {
    showOptInTwo() {
      return this.formItems.isOptInTwo;
    },
    getDataUrl() {
      return this.formItems.dataApiEndpoint;
    },
    getButtonText() {
      return this.formItems.submitForm;
    }
  },
  methods: {
    formSubmit () {
      this.$formkit.submit('subscriberForm')
    },
    generateFormPayload (data) {
      const payload = {
        'ib-submission-source': this.formItems.ibSource,
        'ib-submission-form-campaign': this.formItems.ibCampaign,
        country: this.formItems.market
      }
      return Object.assign({}, payload, data);
    },
    sendFormData (data) {
      const payload = {
        url: this.getDataUrl,
        store: this.storeName,
        payload: this.generateFormPayload(data),
        isViews: false
      };
      this.$store.dispatch('sendFormData', payload);
    }
  }

}
</script>

<style lang="scss">
.subscription-form {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}

.input-group {
  width: 317px;
  margin: 0 auto;
}

.name-inputs {
    width: 100%; 
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  appearance: none;
  background-color: transparent !important;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="149" height="43" viewBox="0 0 149 43" fill="none"><path d="M125.724 42.7273C104.682 42.7273 83.64 42.7273 62.5981 42.7273C51.833 42.7273 41.0285 43.0429 30.2713 42.7273C23.302 42.5301 13.5951 42.0841 8.5 38.0897C3.4049 34.0953 0 28.091 0 21.2364C0 9.44322 9.23385 0.0896833 22.5 0.0896833C50.7081 0.0896833 78.5871 0.395448 107 0.0897019C115.048 0.000937074 119.728 -0.295819 129.747 1.06519C134.787 1.74975 138.5 2.58972 142.5 5.58972C145.209 7.22721 148.804 14.8732 148.5 19.5897C147.5 35.0897 138.5 41.5897 125.724 42.7273Z" fill="white"/></svg>') !important;
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset; 
  border-radius: 20px;
}

.first-name-input::placeholder ,
.last-name-input::placeholder ,
.email-input::placeholder  {
  color: #22272199;
}

.first-name-input,
.last-name-input,
.email-input {
    font-family:  Innocent Light, sans-serif; 
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="149" height="43" viewBox="0 0 149 43" fill="none"><path d="M125.724 42.7273C104.682 42.7273 83.64 42.7273 62.5981 42.7273C51.833 42.7273 41.0285 43.0429 30.2713 42.7273C23.302 42.5301 13.5951 42.0841 8.5 38.0897C3.4049 34.0953 0 28.091 0 21.2364C0 9.44322 9.23385 0.0896833 22.5 0.0896833C50.7081 0.0896833 78.5871 0.395448 107 0.0897019C115.048 0.000937074 119.728 -0.295819 129.747 1.06519C134.787 1.74975 138.5 2.58972 142.5 5.58972C145.209 7.22721 148.804 14.8732 148.5 19.5897C147.5 35.0897 138.5 41.5897 125.724 42.7273Z" fill="white"/></svg>');
    background-color: transparent;
    background-size: cover;
    border: 0;
    width: 150px; 
    height: 43px;
    padding: 0 15px;
    
    &:focus {
        outline: 0;
    }
}

.email-input {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="317" height="45" viewBox="0 0 317 45" fill="none"><path d="M274.858 43.7274C228.856 43.7274 182.854 43.7274 136.852 43.7274C113.318 43.7274 89.6969 44.043 66.1794 43.7274C50.943 43.5301 22.3187 43.8711 11.1798 39.8767C0.0408174 35.8822 0 29.091 0 22.2364C0 10.4433 5.08171 0.114243 43.2129 0.755322C104.882 0.755322 169.081 0.419989 231.198 0.114243C248.793 0.0254782 262.216 0.114298 284.121 1.4753C300.837 0.755323 303.39 2.30988 309.984 6.45223C313.066 8.38534 315.041 13.1331 316.418 15.6382C319.639 34.3891 309.984 46.362 274.858 43.7274Z" fill="white"/></svg>');
  width: 317px;
}

.input-group .formkit-label {
  display: none;
}

.formkit-message {
  font-size: 16px;
  color: #ECB9CC;
}
// checkbox section
.checkbox-section {
    width: 320px;
    margin: 20px auto 0;
}
.checkbox-section .formkit-outer:not(:last-of-type) {
  margin-bottom: 20px;
}
.checkbox-section .formkit-label {
  font-size: 15px;
}

.checkbox-section .formkit-wrapper {
  font-family: Innocent Light;   
  color: #fff; 
  display: flex;
  text-align: left;
}

.checkbox-section .formkit-inner {
    position: relative;
    display: inline-block;
    padding-left: 55px;
    text-align: left;
    font-size: 15px;
}

.checkbox-section .formkit-inner input {
    opacity: 0;
    width: 0;
    height: 0;
}

.checkbox-section .slider {
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -13px;
    background: #B1B1B1;
    transition: .4s;
}

.checkbox-section .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    bottom: 3px;
    left: 3px;
    background-color: white;
    transition: .4s;
}

.checkbox-section input:checked+.slider {
  background: #71A850;
}

.checkbox-section input:checked+.slider:before {
    transform: translateX(19px);
}

.checkbox-section .slider.round {
    border-radius: 34px;
    width: 45px;
    height: 26px;
}

.checkbox-section .slider.round:before {
    border-radius: 50%;
}

// hiding Form error message

#subscriberForm-incomplete {
  display: none;
}

</style>