<template>
  <div class="subscription-form">
    <div class="subscription-form-text">
      <subscription-form-logo
       :imageUrl="imageLogoUrl"
       :imageAltText="imageLogoAltText" 
       class="subscription-form-logo"
      />
      <subscription-form-title 
        :title="getTitle"
        :showMarkers="showForm" />
      <subscription-form-description
        v-if="!showForm"
        :descriptionText="getDescription"
      />
      <subscription-form-button 
        v-if="showCTAButton"
        :buttonText="getButtonText"
        @click="ctaButtonHandler"
      />
      <subscription-form-image-success 
        v-if="showSuccess"
        :imageSuccessUrl="getImageSuccessUrl"
        :imageSuccessUrlAltText="getImageSuccessUrlAltText" 
        class="subscription-form-logo" 
      />
      <subscription-form 
        v-if="showForm"
        :formItems="formItems"
        :storeName="storeName"
      />
      <subscription-form-button-close 
        v-if="isNav"
        @click="closePopUp" 
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubscriptionFormLogo from "./SubscriptionFormLogo";
import SubscriptionFormImageSuccess from "./SubscriptionFormImageSuccess";
import SubscriptionFormTitle from "./SubscriptionFormTitle";
import SubscriptionFormButtonClose from "./SubscriptionFormButtonClose";
import SubscriptionFormButton from "./SubscriptionFormButton";
import SubscriptionFormDescription from "./SubscriptionFormDescription";
import SubscriptionForm from "./SubscriptionForm";

export default {
  name: 'SubscriptionFormContainer',
  components: {
    SubscriptionFormLogo,
    SubscriptionFormImageSuccess,
    SubscriptionFormTitle,
    SubscriptionFormDescription,
    SubscriptionForm,
    SubscriptionFormButton,
    SubscriptionFormButtonClose,
  },
  props: {
    formItems: {
      type: Object,
      required: true,
    },
    imageLogoUrl: {
      type: String,
      required: true
    },
    imageLogoAltText: {
      type: String,
      required: true
    },
    storeName: {
      type: String,
      required: true
    },
    isNav: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    ...mapGetters({
      dataReady: 'subscribeForm/getDataReady',
      showNavIntro: 'subscribeForm/showNavIntro',
      showNavForm: 'subscribeForm/showNavForm',
      showNavSuccess: 'subscribeForm/showNavSuccess',
      showNavError: 'subscribeForm/showNavError',
      showPageIntro: 'subscribeForm/showPageIntro',
      showPageForm: 'subscribeForm/showPageForm',
      showPageSuccess: 'subscribeForm/showPageSuccess',
      showPageError: 'subscribeForm/showPageError',
      viewsSuccess: 'subscribeForm/viewsSuccess'
    }),
    showIntro() {
      return this.isNav ? this.showNavIntro : this.showPageIntro;
    },
    showForm() {
      return this.isNav ? this.showNavForm : this.showPageForm;
    },
    showSuccess() {
      return this.isNav ? this.showNavSuccess : this.showPageSuccess;
    },
    showError() {
      return this.isNav ? this.showNavError : this.showNavError;
    },
    getTitle() {
      switch (true) {
        case !this.dataReady:
          return '';
        case this.showIntro:
          return this.formItems.titleIntro;
        case this.showForm:
          return this.formItems.titleForm;
        case this.showSuccess:
          return this.formItems.titleSuccess;
        case this.showError:
          return this.formItems.titleError;
        default:
          return '';
      }
    },
    getDescription() {
      switch (true) {
        case !this.dataReady:
          return '';
        case this.showIntro:
          return this.formItems.descriptionIntro?.plaintext;
        case this.showSuccess:
          return this.formItems.descriptionSuccess?.plaintext;
        case this.showError:
          return this.formItems.descriptionError?.plaintext;
        default:
          return '';
      }
    },
    getButtonText() {
      switch (true) {
        case !this.dataReady:
          return '';
        case this.showIntro:
          return this.formItems.ctaIntroText;
        case this.showError:
          return this.formItems.ctaErrorText;
        default:
          return '';
      }
    },
    getViewsUrl() {
      return this.formItems.viewsApiEndpoint;
    },
    showCTAButton() {
      return this.showIntro || this.showError;
    },
    getImageSuccessUrl() {
      return this.formItems.imageSuccessUrl?._path;
    },
    getImageSuccessUrlAltText() {
      return this.formItems.imageSuccessUrlAltText;
    }
  },
  methods: {
    closePopUp() {
      this.$store.dispatch('subscribeForm/updatePopUp', { popUpStatus: false});
    },
    closeIntro() {
      if (!this.viewsSuccess) {
        const payload = {
          url: this.getViewsUrl,
          store: this.storeName,
          payload: {},
          isViews: true
        };
        this.$store.dispatch('sendFormData', payload);
      }
      this.$store.dispatch('subscribeForm/updateIntro', { introStatus: false});
    },
    resetForm() {
      this.$store.dispatch('subscribeForm/resetForm', {});
    },
    ctaButtonHandler() {
      if (this.showIntro) {
        this.closeIntro();
      }
      if (this.showError) {
        this.resetForm();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.subscription-form {
  max-width: 460px;
  width: 100%;
  border-radius: 20px;
  background-color: #A4243B;
  text-align: center;
  padding: 20px 20px 50px 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;

  @media only screen and (max-width: 768px) {
    padding: 20px 12px 50px 12px;
  }
}

.subscription-form-text {
  width: 100%;
}
</style>